import { useToast } from 'vue-toastification';
import { clienteStore } from '../../store/modules/clientes';
import { IClienteRequest } from './../../models/IClienteRequest';
import { DxForm, DxSimpleItem, DxRequiredRule, DxEmailRule, DxCustomRule, DxButtonItem, DxButtonOptions, DxGroupItem } from 'devextreme-vue/ui/form';
import { defineComponent, onMounted, reactive, ref } from "vue";
import Validators from '../../helpers/validators-input';
import ContabilistaService from '../../services/contabilistas/ContabilistaService';
import eventBus from '../../helpers/event-bus';
import { authStore } from '../../store/modules/auth';

export default defineComponent({
  name: 'FormCliente',
  props: {
    propsComponent: {
      required: true,
      type: Object as () => IClienteRequest
    }
  },
  setup(props: any) {
    onMounted(() => {
      GetContabilistas()
    })

    const toast = useToast()
    const form = ref()
    const popup = eventBus.usePopup()
    const store = clienteStore()
    const auth = authStore();
    const state = reactive({
      cliente: props.propsComponent,
      contabilistas: {} as Record<string, string>,
    })

    const saveButtonProperties = {
      text: 'Salvar',
      type: 'default',
      stylingMode: 'text',
      icon: 'mdi mdi-content-save',
      useSubmitBehavior: true,
    }

    const cancelButtonProperties = {
      text: 'Cancelar',
      type: 'danger',
      stylingMode: 'text',
      onClick: () => popup.close()
    }

    const masks = {
      cpf: '000.000.000-00',
      cnpj: '00.000.000/0000-00'
    }

    const cnpjCpfOptions = {
      readOnly: state.cliente.isReadonly,
      valueChangeEvent: 'keyup',
      mask: masks.cpf,
      onKeyDown: setMask
    }

    function setMask(args: any) {
      const value = args.component.option('value')
      const mask = value.toString().length > 10 ? masks.cnpj : masks.cpf;
      args.component.option('mask', mask);
    }

    function validateCnpjCpf(e: any) {
      const value = e.value
      return Validators.valida_cpf_cnpj(value)
    }

    async function GetContabilistas() {
      try {
        const response = await ContabilistaService.GetContabilistas();
        if (response.status === 200) state.contabilistas = response.data
      } catch (error: any) {
        toast.error(error.response.data)
      }
    }

    return {
      form,
      state,
      saveButtonProperties,
      cancelButtonProperties,
      cnpjCpfOptions,
      validateCnpjCpf,
      store,
      auth,
    }
  },
  components: {
    DxForm,
    DxCustomRule,
    DxEmailRule,
    DxRequiredRule,
    DxSimpleItem,
    DxButtonItem,
    DxButtonOptions,
    DxGroupItem
  }
})